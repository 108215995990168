// routes
import { paths } from 'src/routes/paths';

// API
// ----------------------------------------------------------------------

console.log("Carregando configurações do sistema");

console.log("Enviroment");
console.log(process.env.REACT_APP_NODE_ENV);
console.log("Acesso");
console.log(process.env.REACT_APP_HOST_API);
console.log("Core");
console.log(process.env.REACT_APP_CORE_HOST_API);


export const BASE_API = process.env.REACT_APP_BASE_API || "https://portal.zapizi.com.br";
export const BASE_ZAPIZI_API = process.env.REACT_APP_BASE_ZAPIZI_API || "https://base.zapizi.com.br";
export const HOST_API = process.env.REACT_APP_HOST_API || "/v1/access";
export const CORE_API = process.env.REACT_APP_CORE_HOST_API || "/v1/core";
export const PAYMENT_GATEWAY_API = process.env.REACT_APP_PAYMENT_GATEWAY_API || "/v1/payment-gateway";
export const STATEMENT_API = process.env.REACT_APP_STATEMENT_HOST_API || "/v1/statement";
export const EVENTS_API = process.env.REACT_APP_EVENTS_HOST_API || "/v1/events";
export const CONSOLIDATION_API = process.env.REACT_APP_CONSOLIDATION_API || "/statement-consolidation";
export const ASSETS_API = process.env.REACT_APP_ASSETS_API;
export const LOGO_BASE_URL = process.env.REACT_APP_LOGO_BASE_URL || "https://pos-logos.s3.us-east-2.amazonaws.com";

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const ADMIN_PATH_AFTER_LOGIN = paths.dashboard.root; // as '/dashboard'
export const MANAGER_PATH_AFTER_LOGIN = paths.dashboard.root; // as '/dashboard'
export const OPERATOR_PATH_AFTER_LOGIN = paths.dashboard.machine.list; // as '/dashboard'
