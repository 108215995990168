export enum USER_PERMISSIONS {
    DASHBOARD_VIEW = "dashboard-view",
    MACHINES_VIEW = "machines-view",
    MACHINES_WRITE = "machines-write",
    MACHINES_TOGGLE = "machines-toggle",
    SALES_VIEW = "sales-view",
    SALES_REFUND = "sales-refund",
    USER_VIEW = "user-view",
    USER_WRITE = "user-write",
    USER_TOGGLE = "user-toggle",
    LOCATIONS_VIEW = "locations-view",
    STATEMENTS_VIEW = "statements-view",
    COUPON_VIEW = "coupon-view",
    SALES_EXPORT = "sales-export",
    LOCATIONS_WRITE = "locations-write",
    LOCATIONS_TOGGLE = "locations-toggle",
    STATEMENTS_REDEEM = "statements-redeem",
    COUPON_TOGGLE = "coupon-toggle",
    TELEMETRY_VIEW = "telemetry-view",
    VISIT_VIEW = "visit-view",
    VISIT_ADD = "visit-add",
    VISIT_UPDATE = "visit-update",
    MANUNAL_SALE = "manual-sale",
}

export enum USER_ROLES {
    ADMIN = "Administrador",
    OPERATOR = "Operador",
    MANAGER = "Gerente",
} 

export enum SAAS_PERMISSIONS {
    CONSOLIDATION_VIEW = "consolidation-view",
}